
function HeaderGame() {
    return (
      <div className="header-container-game" aria-label="Memory Game Header">
        <h1 className="header-game">Memory<br />Game</h1> 
      </div>
    );
  }
  
  export default HeaderGame;
  