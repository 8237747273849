
function HeaderHome() {
  return (
    <div className="header-container-home" aria-label="Memory Game Header">
      <h1 className="header-home">Memory<br />Game</h1> 
    </div>
  );
}

export default HeaderHome;
